import React from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import '@reach/dialog/styles.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { FiltersContextProvider } from './context/filtersContext';
import { SideNavContextProvider } from './context/sideNavContext';
import { LoadingContextProvider } from './context/loadingContext';
import 'rc-picker/assets/index.css';
import GlobalStyles from './styles/GlobalStyles.styles';
import { StyledToastContainer } from './styles/App.styles';
import { theme } from './config/theme';
import Router from './Router';
import { AuthContextProvider } from './context/authContext';

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <LoadingContextProvider>
          <SideNavContextProvider>
            <FiltersContextProvider>
              <GlobalStyles />
              <BrowserRouter>
                {/* <Suspense fallback="loading..."> */}
                <AuthContextProvider>
                  <Router />
                </AuthContextProvider>
                {/* </Suspense> */}
              </BrowserRouter>
              <StyledToastContainer />
            </FiltersContextProvider>
          </SideNavContextProvider>
        </LoadingContextProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
