/* eslint-disable jsx-a11y/no-onchange */
import React from "react";
// eslint-disable-next-line no-unused-vars
import styled from "styled-components/macro";

import DatePickerHeader from "../DatePickerHeader";
import { StyledDateRange } from "./DatePicker.styles";
import MaskedTextInput from "react-text-mask";

function ReactDateRange({
  prefix,
  disabled,
  excludeDateIntervals,
  invalid,
  error,
  onChange,
  ...props
}) {
  return (
    <StyledDateRange
      disabled={disabled}
      excludeDateIntervals={excludeDateIntervals}
      prefix={prefix}
      dateFormat="yyyy-MM-dd"
      invalid={invalid || error}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <DatePickerHeader
          date={date}
          changeYear={changeYear}
          changeMonth={changeMonth}
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
          prevMonthButtonDisabled={prevMonthButtonDisabled}
          nextMonthButtonDisabled={nextMonthButtonDisabled}
        />
      )}
      {...props}
      customInput={
        <MaskedTextInput
          type="text"
          mask={[/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/]}
          placeholder="YYYY-MM-DD"
        />
      }
      onChange={(_) => {
        let updatedDate = "";
        if (_) {
          // Creating a new Date object based on the received date
          updatedDate = new Date(_);

          // Setting hours, minutes, and seconds to zero
          updatedDate.setHours(0, 0, 0, 0);
        }
        onChange({ target: { value: updatedDate, name: props.name } });
      }}
    />
  );
}

export default ReactDateRange;
