import React, { useContext } from 'react';
import { Switch } from 'react-router-dom';
import PublicRoute from './helpers/publicRoute';
import PrivateRoute from './helpers/privateRoute';
import { AuthContext } from './context/authContext';
import Login from './pages/login';
import Routes from './pages';
import Development from './pages/development';

function Router() {
  const { isLoggedIn, allowedPages } = useContext(AuthContext);
  return (
    <Switch>
      <PrivateRoute path="/admin/:view/:child?" component={Routes} isLoggedIn={isLoggedIn} />
      <PublicRoute path="/development" component={Development} />
      <PublicRoute
        restricted
        path="*"
        component={Login}
        isLoggedIn={isLoggedIn}
        redirectTo={allowedPages.length > 0 ? allowedPages[0] : '/dashboard'}
      />
    </Switch>
  );
}

export default Router;
