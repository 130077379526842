import styled from 'styled-components/macro';
import BG from '../../../assets/images/bg.jpg';

export const TemplateHolder = styled.div`
  min-height: 100vh;
  height: 100%;
  position: relative;
  z-index: 1;
  background-image: url(${BG});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--primary-gradient);
    z-index: -1;
    opacity: 0.6;
  }
`;

export const Content = styled.div`
  padding: 1rem;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
  @media (min-width: 992px) {
    padding: 3.75rem 4rem;
  }
`;

export const ContentHolder = styled.div`
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  padding: 80px 30px;
  @media (min-width: 768px) {
    padding: 90px 45px;
  }
`;
