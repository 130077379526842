/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
import React, { useState, createContext, useEffect } from 'react';
import { clearCookie, getCookie, setCookie } from 'helpers/common';
import Toast from 'components/molecules/Toast';
import adminService from 'services/adminService';
import { useCancellablePromise } from 'helpers/promiseHandler';

const context = {};

export const AuthContext = createContext(context);

export const AuthContextProvider = props => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!getCookie(process.env.REACT_APP_ADMIN_PLASTK_TOKEN_COOKIE));
  const [loading, setLoading] = useState(false);
  const [isBusiness, setIsbusiness] = useState(false);
  const [isRider, setIsRider] = useState(false);
  const [user, setUser] = useState({});
  const [loading_user, setLoadingUser] = useState(false);
  const [fetch_user, setFetchUser] = useState(false);
  const { cancellablePromise } = useCancellablePromise();
  const [reFetch, setRefetch] = useState(false);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [allowedPages, setAllowedPages] = useState(JSON.parse(getCookie('allowed_pages')) || []);
  const onLogout = async () => {
    try {
      if (isLoggedIn) setLoadingUser(true);

      clearCookie(process.env.REACT_APP_ADMIN_PLASTK_TOKEN_COOKIE);
      clearCookie(process.env.REACT_APP_ALLOWED_PAGES_COOKIE);
    } catch (ex) {
      clearCookie(process.env.REACT_APP_ADMIN_PLASTK_TOKEN_COOKIE);
      clearCookie(process.env.REACT_APP_ALLOWED_PAGES_COOKIE);
      Toast({ type: 'error', message: ex?.message });
    } finally {
      setLoadingUser(false);
      setIsLoggedIn(false);
    }
  };

  const getPermissions = () => {
    setLoadingUser(true);
    cancellablePromise(adminService.getCurrentAdmin(getCookie(process.env.REACT_APP_ADMIN_PLASTK_TOKEN_COOKIE)))
      .then(res => {
        setAllowedPages(res.permissions);
        setCookie('allowed_pages', JSON.stringify(res.permissions));
        setLoadingUser(false);
      })
      .catch(err => {
        setAllowedPages(['no-permissions']);
        setCookie(process.env.REACT_APP_ALLOWED_PAGES_COOKIE, JSON.stringify(['no-permissions']));
        setLoadingUser(false);
        Toast({
          type: 'error',
          message: err.message,
        });
      });
  };
  /**
   * @description - This function is used to fetch the user details from the server
   */
  useEffect(() => {
    if (isLoggedIn && getCookie(process.env.REACT_APP_ADMIN_PLASTK_TOKEN_COOKIE)) {
      getPermissions();
    }
    // listen to event
    window.addEventListener('FETCH_ADMIN_ROLE', () => {
      getPermissions();
    });
    return () => {
      window.removeEventListener('FETCH_ADMIN_ROLE', () => {
        getPermissions();
      });
    };
  }, [isLoggedIn, fetch_user]);

  const onLogin = async ({ email, password, token }) => {
    setLoadingUser(true);
    try {
      const res = await adminService.login({ email, password, token });
      if (res.admin) {
        setIsLoggedIn(true);
        setUser(res.admin);
        setCookie(process.env.REACT_APP_ADMIN_PLASTK_TOKEN_COOKIE, res.admin._id);
        setIsbusiness(false);
        setAllowedPages(['students', 'attendence', 'instructors']);
      }
      setLoadingUser(false);
    } catch ({ message }) {
      setIsLoggedIn(false);
      setLoadingUser(false);
      Toast({ type: 'error', message });
    }
  };

  /**
   * @description - If someone tries to temper with the cookies we take the appropriate action
   */
  useEffect(() => {
    function listenCookieChange(callback, interval) {
      let old_bap_token = getCookie(process.env.REACT_APP_ADMIN_PLASTK_TOKEN_COOKIE);
      let old_allowed = getCookie(process.env.REACT_APP_ALLOWED_PAGES_COOKIE);
      setInterval(() => {
        const new_bap_token = getCookie(process.env.REACT_APP_ADMIN_PLASTK_TOKEN_COOKIE);
        const new_allowed = getCookie(process.env.REACT_APP_ALLOWED_PAGES_COOKIE);
        if (new_bap_token !== old_bap_token) {
          try {
            callback(new_bap_token, process.env.REACT_APP_ADMIN_PLASTK_TOKEN_COOKIE);
          } finally {
            old_bap_token = new_bap_token;
          }
        }
        if (new_allowed !== old_allowed) {
          try {
            callback(new_allowed, process.env.REACT_APP_ALLOWED_PAGES_COOKIE);
          } finally {
            old_allowed = new_allowed;
          }
        }
      }, interval);
    }
    listenCookieChange((value, cookie) => {
      if (cookie === process.env.REACT_APP_ADMIN_PLASTK_TOKEN_COOKIE) {
        if (!value) {
          onLogout();
        }
      }
      if (cookie === process.env.REACT_APP_ALLOWED_PAGES_COOKIE) {
        if (JSON.stringify(allowedPages) !== value && isLoggedIn) {
          // getPermissions();
        }
      }
    }, 1000);
  }, []);

  const hasPermission = perm => user?.permissions?.includes(perm);
  return (
    <AuthContext.Provider
      value={{
        setIsLoggedIn,
        onLogout,
        onLogin,
        refetch: () => setRefetch(_ => !_),
        fetchUser: () => setFetchUser(() => !fetch_user),
        setShowTokenModal,
        setLoading,
        hasPermission,
        allowedPages,
        showTokenModal,
        loading,
        isBusiness,
        isRider,
        isLoggedIn,
        fetch: reFetch,
        user,
        loading_user,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};
