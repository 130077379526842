/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useContext, useState, useEffect } from "react";
import Form, { useForm } from "components/molecules/Form";
import Field from "components/molecules/Field";
import Toast from "components/molecules/Toast";
import Button from "components/atoms/Button";
// eslint-disable-next-line no-unused-vars
import { css } from "styled-components/macro";
import { AuthContext } from "context/authContext";
import adminService from "services/adminService";
import Grid from "../../atoms/Grid";
import Select from "components/atoms/Select";
import { getDateObject } from "helpers/common";
import { format } from "date-fns";

const AttendenceForm = ({ onClose, attendence }) => {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [studentList, setStudentList] = useState({});
  const [sessionDate, setSessionDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [instLicExpiry, setInstLicExpiry] = useState("");
  const [instdructorOptions, setInstructorOptions] = useState({
    classInstructors: [],
  });
  const [form] = useForm();
  const { refetch } = useContext(AuthContext);

  useEffect(() => {
    adminService
      .getInstructorsList()
      .then((response) => {
        setInstructorOptions({
          classInstructors: response?.in_class_instructors?.map((e) => ({
            label: e?.instructor_name,
            value: e?._id,
          })),
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  useEffect(() => {
    const id = state?.in_class_instructor_select?.value;
    if (id) {
      adminService
        .getSingleInstructor(id)
        .then((response) => {
          form.setFieldsValue({
            inst_name: response?.singleInstructor?.instructor_name,
            instr_driving_lisence: response?.singleInstructor?.driving_lisence,
            inst_expiry_date: new Date(response?.singleInstructor?.expiry_date)
              ?.toISOString()
              ?.split("T")[0],
          });
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  }, [state?.in_class_instructor_select]);

  useEffect(() => {
    setLoading(true);
    adminService
      .getStudentList()
      .then((res) => {
        setStudentList(res?.studentList);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });
  }, []);

  console.log({ attendence });
  useEffect(() => {
    if (attendence) {
      setSessionDate(new Date(attendence?.session_date));
      form.setFieldsValue({
        students: attendence?.students?.map((e) => ({
          label: e,
          value: e,
        })),
        course_number: attendence?.course_number,
        session_number: attendence?.session_number,
        session_date: format(new Date(attendence?.session_date), "yyyy-MM-dd"),

        inst_name: attendence?.inst_name,
        instr_driving_lisence: attendence?.instr_driving_lisence,
        inst_expiry_date: attendence?.inst_expiry_date ?? null,
      });
      if (attendence?.inst_name) {
        form.setFieldsValue({
          in_class_instructor_select: {
            label: attendence?.inst_name,
            value: attendence?.inst_name,
          },
        });
      }
      if (attendence?.session_start_time) {
        form.setFieldsValue({
          session_start_time: attendence?.session_start_time,
        });
      }
      if (attendence?.session_end_time) {
        form.setFieldsValue({
          session_end_time: attendence?.session_end_time,
        });
      }
    }
  }, []);

  const onDownloadPdf = async (id, sheet, type) => {
    try {
      const res = await adminService.downloadPdf({ id, type });
      const a = document.createElement("a");
      a.href = URL.createObjectURL(new Blob([res]));
      a.download = `Attendence-Sheet/${format(
        getDateObject(sheet.session_date),
        "yyyy-MM-dd"
      )}.pdf`;
      a.click();
      setLoading(false);
      document.body.appendChild(a);
      document.body.removeChild(a);
      refetch();
      setLoading(false);
      onClose();
      Toast({
        message: "Attendence Created Successfully and PDF form Downloaded",
        type: "success",
      });
    } catch (ex) {
      refetch();
      setLoading(false);
      onClose();
      Toast({
        type: "error",
        message:
          "Attendence Created but failed to download pdf kindly download it manually",
      });
    }
  };

  const handleSubmit = async (e) => {
    const payload = {
      ...e,
      students: e.students.map((e) => e.label),
      session_date: format(new Date(sessionDate), "yyyy-MM-dd"),
    };
    console.log({ payload });
    setLoading(true);
    try {
      if (attendence) {
        await adminService.updateAttendence(payload, attendence?._id);
        onDownloadPdf(attendence?._id, payload, "attendence");
      } else {
        const res=await adminService.createAttendenceSheet(payload);
        onDownloadPdf(res?.newAttendence?._id, payload, "attendence");
      }
    } catch (ex) {
      console.log({ ex });
      setLoading(false);
      onClose();
      Toast({
        type: "error",
        message: ex?.message,
      });
    }
  };

  return (
    <Form
      form={form}
      onSubmit={handleSubmit}
      onTouched={(e) => {
        setState((_) => ({ ..._, ...e }));
      }}
      onError={() => {
        Toast({
          type: "error",
          message: "Please fill all the required fields",
        });
      }}
    >
      <Form.Item
        name="students"
        label="Select Students"
        rules={[
          { required: true, message: "Select atleast one student" },
          {
            transform: (value) => !value?.length,
            message: "Select at least one student",
          },
          {
            transform: (value) => value?.length > 15,
            message: "Maximum Student limit is 15",
          },
        ]}
      >
        <Select
          sm
          options={studentList}
          isSearchable
          loading={loading}
          isMulti
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
        />
      </Form.Item>
      <Grid xs={1} lg={3} colGap={20}>
        <Form.Item
          type="text"
          label="Course #"
          name="course_number"
          placeholder="Course #"
          rules={[
            { required: true, message: "Course # is required" },
            {
              pattern: /^[a-zA-Z0-9\s`',.]*$/,
              message: "Enter a valid Course #",
            },
            {
              pattern: /^[a-zA-Z0-9\s`!@#$%^&*()_+-=:";'<>,.]{1,}$/,
              message: "Course # must be minimum 1 characters",
            },
            {
              pattern: /^[a-zA-Z0-9\s`!@#$%^&*()_+-=:";'<>,.]{1,30}$/,
              message: "Course # must be maximum 1 characters",
            },
          ]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          label="Session #"
          name="session_number"
          placeholder="Session #"
          rules={[
            { required: true, message: "Session # is required" },
            {
              pattern: /^[a-zA-Z0-9\s`',.]*$/,
              message: "Enter a valid Session #",
            },
            {
              pattern: /^[a-zA-Z0-9\s`!@#$%^&*()_+-=:";'<>,.]{1,}$/,
              message: "Session # must be minimum 1 characters",
            },
            {
              pattern: /^[a-zA-Z0-9\s`!@#$%^&*()_+-=:";'<>,.]{1,30}$/,
              message: "Session # must be maximum 30 characters",
            },
          ]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          sm
          name="session_date"
          label="Session date"
          selected={sessionDate ? new Date(sessionDate) : new Date()}
          onChange={(date) => {
            form.setFieldsValue({
              session_date: date?.target?.value ? date.target.value : "",
            });
            if (date.target.value === null) {
              setSessionDate("");
            } else {
              setSessionDate(date.target.value);
            }
          }}
          isClearable
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholderText="Session date"
          type="datepicker"
          rules={[{ required: true, message: "Session is required" }]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          label="Start Time"
          name="session_start_time"
          placeholder="Start Time"
          rules={[
            {
              pattern: /^([01][0-9]|2[0-3]):([0-5][0-9])$/,
              message: "Enter a valid time value i.e 06:00, 00:59, 20:15",
            },
          ]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          label="End Time"
          name="session_end_time"
          placeholder="End Time"
          rules={[
            {
              pattern: /^([01][0-9]|2[0-3]):([0-5][0-9])$/,
              message: "Enter a valid time value i.e 06:00, 00:59, 20:15",
            },
          ]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          name="in_class_instructor_select"
          label="In Class Instructor"
          rules={[{ required: true, message: "Instructor is required" }]}
        >
          <Select
            sm
            options={instdructorOptions?.classInstructors}
            isSearchable
            hideSelectedOptions={false}
            closeMenuOnSelect={true}
          />
        </Form.Item>
      </Grid>
      <Grid xs={1} lg={3} colGap={20}>
        <Form.Item
          type="text"
          label="In Class Instructor"
          name="inst_name"
          disabled
          placeholder="In Class Instructor Name"
        >
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          label="In Class Driving License #"
          name="instr_driving_lisence"
          disabled
          placeholder="Driving License #"
        >
          <Field />
        </Form.Item>
        <Form.Item
          name="inst_expiry_date"
          label="In Class Instr Licence Expiry"
          disabled
          selected={instLicExpiry ? new Date(instLicExpiry) : new Date()}
          onChange={(date) => {
            form.setFieldsValue({
              inst_lic_expiry: date?.target?.value ? date?.target?.value : "",
            });
            if (date.target.value === null) {
              setInstLicExpiry("");
            } else {
              setInstLicExpiry(getDateObject(date.target.value));
            }
          }}
          isClearable
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholder="Select Expiry Date"
          type="text"
        >
          <Field />
        </Form.Item>
      </Grid>

      <Button
        loading={loading}
        type="primary"
        htmlType="submit"
        rounded
        sm
        width={155}
        css={`
          margin: 0 auto 1.25rem;
          @media (min-width: 768px) {
            margin-bottom: 2.125rem;
          }
        `}
      >
        Submit
      </Button>
    </Form>
  );
};

export default AttendenceForm;
