import React, { useContext } from 'react';

// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';
import { useMediaPredicate } from 'react-media-hook';
import { LoadingContext } from 'context/loadingContext';
import Header from 'components/molecules/Header';

import topNavData from 'nav.json';
import { useParams } from 'react-router-dom';
import UserForm from 'components/organisms/UserForm';
import AttendenceForm from 'components/organisms/AttendenceForm';
import InstructorForm from 'components/organisms/InstructorForm';
import ModalContainer from 'components/molecules/ModalContainer';
import Button from 'components/atoms/Button';
import Tooltip from 'components/atoms/Tooltip';

function TopBar() {
  const { isLoading } = useContext(LoadingContext);
  let { view: title, child } = useParams();

  const MaxWidth991 = useMediaPredicate('(max-width: 991px) ');
  // eslint-disable-next-line prefer-const
  let { buttons = [], subNav = [] } = topNavData.find(
    ({ file }) => file === title
  );

  if (subNav?.length) {
    const { buttons: subNavButtons } = subNav.find(
      ({ file }) => file === child
    ) ?? { buttons: [] };
    if (child) {
      buttons = [...subNavButtons];
    } else {
      buttons = [...buttons, ...subNavButtons];
    }
  }
  //   try {
  //     const res = await businessUsersService.removeFakeData();
  //     if (res) {
  //       Toast({
  //         type: 'success',
  //         message: 'Data removed successfully',
  //       });
  //     }
  //   } catch (ex) {
  //     Toast({
  //       type: 'error',
  //       message: ex?.message,
  //     });
  //   }
  // };
  // const restorePermissions = async () => {
  //   try {
  //     const res = await adminService.restorePermissions({ permissions: Permissions, roles: defaultRoles() });
  //     if (res) {
  //       Toast({
  //         type: 'success',
  //         message: 'Permissions restored successfully',
  //       });
  //     }
  //   } catch (ex) {
  //     Toast({
  //       type: 'error',
  //       message: ex?.message,
  //     });
  //   }
  // };

  return (
    <>
      <Header
        title={child ? child.split('-').join(' ') : title.split('-').join(' ')}
      >
        {isLoading ? (
          <Skeleton
            rectangle
            height={40}
            width={131}
            css='border-radius:8px !important;'
          />
        ) : (
          <>
            {buttons.includes('users.create') && (
              <ModalContainer
                title='Add Student'
                width={900}
                isClosable={false}
                btnComponent={({ onClick }) => (
                  <Tooltip title='Create User' type='dark'>
                    <Button
                      size={200}
                      type='primary'
                      onClick={onClick}
                      iconMobile
                      prefix={
                        <span className='material-icons-outlined'>add</span>
                      }
                    >
                      <span className='text'>Add Student</span>
                    </Button>
                  </Tooltip>
                )}
                content={({ onClose }) => <UserForm onClose={onClose} />}
              />
            )}
            {buttons.includes('instructors.create') && (
              <ModalContainer
                title='Add Instructor'
                lg
                isClosable={false}
                btnComponent={({ onClick }) => (
                  <Tooltip title='Create Instructor' type='dark'>
                    <Button
                      size={200}
                      type='primary'
                      onClick={onClick}
                      iconMobile
                      prefix={
                        <span className='material-icons-outlined'>add</span>
                      }
                    >
                      <span className='text'>Add Instructor</span>
                    </Button>
                  </Tooltip>
                )}
                content={({ onClose }) => <InstructorForm onClose={onClose} />}
              />
            )}
            {buttons.includes('attendence.create') && (
              <ModalContainer
                title='Attendence Sheet'
                lg
                isClosable={false}
                btnComponent={({ onClick }) => (
                  <Tooltip title='Attendence Sheet' type='dark'>
                    <Button
                      size={200}
                      type='primary'
                      onClick={onClick}
                      iconMobile
                      prefix={
                        <span className='material-icons-outlined'>
                          description
                        </span>
                      }
                    >
                      <span className='text'>Attendence</span>
                    </Button>
                  </Tooltip>
                )}
                content={({ onClose }) => <AttendenceForm onClose={onClose} />}
              />
            )}
          </>
        )}
        {isLoading ? (
          <>
            {!MaxWidth991 && <Skeleton circle height={40} width={40} />}
            {MaxWidth991 && <Skeleton circle height={18} width={18} />}
          </>
        ) : (
          ' '
        )}
      </Header>
    </>
  );
}

export default TopBar;
